<template>
    <!-- Dialog de Product Page -->

    <q-dialog 
        v-model="dialog" transition-show="slide-up" transition-hide="slide-down"
        :maximized="$q.platform.is.mobile ? maximizedToggle : false" class="q-pa-md justify-center" v-if="product"
        @hide="clearProductPage()"
        >
        <!-- Card donde se encuentra el contenido principal -->
    
        <q-card :style="{ 'font-family': settings.typography }" class="q-pa-none q-ma-none omni-content-colors">
            <!-- Parte superior de la Card -->

            <div class="row justify-between items-center q-mt-md">
                <GoBackButton @click="deleteInfo()" />
                <div class="points q-mr-lg desktop-hide">
                    <q-toolbar-title class="title-points ">
                        <div class="column items-end">
                            <div class="omni-text-primary" style="text-transform: capitalize;">
                                {{ $t("General.points") }}
                            </div>
                            <div class="omni-text-secondary">
                                {{ points.points.points }} {{ settings.currency_abbreviation }}
                            </div>
                        </div>
                         
                    </q-toolbar-title>
                </div>
            </div>

            <!-- Carousel con imágenes de producto -->

            <div class="q-pa-md">
                <q-carousel v-model="slide" transition-prev="slide-right" transition-next="slide-left" arrows animated
                    navigation swipeable height="300px" control-color="black" v-if="product.images.length > 0">
                    <q-carousel-slide v-for="(image, index) in product.images" :name="index" class="" :key="index"
                        :img-src="getProductImageUrl(image)"
                        style="background-size: contain; background-repeat: no-repeat" loading="lazy">
                    </q-carousel-slide>
                </q-carousel>
                <div v-else>
                    <q-img :src="defaultImageApp" />
                </div>
            </div>

            <!-- Título y puntos de producto -->

            <q-item class="justify-between q-ma-none items-center q-pl-lg q-pr-lg" style="max-width: 500px">
                <h6 class="title-products q-mt-xs q-mb-xs text-weight-bold">
                    {{ product.name }}
                </h6>
                <q-card-section class="price-products text-weight-bold" style="min-width: 150px">
                    {{ product.value }} {{ settings.currency_abbreviation }}
                </q-card-section>
            </q-item>

            <!-- Descripción y disponibilidad del producto -->
            <!-- Dependen de si el Dialog con las opciones se muestra o no. Si se muestra, se ocultan. -->
            <!-- Si hay Stock (que proviene del stockMessage()), el botón "Lo quiero" se muestra azul. En caso contrario, se muestra disabled-->

            <q-card-section class="q-ml-lg q-mr-lg q-pa-none q-ma-none" style="max-width: 500px"
                v-show="dialogWithOptions == false">
                <div v-html="product.description" class="product-description"></div>
            </q-card-section>
            <q-card-section class="q-ml-lg q-mr-lg q-mt-sm q-pa-none q-ma-none" style="max-width: 500px"
                id="disponibilidad-producto" v-show="dialogWithOptions == false">
                {{ $t("BuyingProcess.products.availability") }}
                <span class="text-weight-bold">{{ stockMessage() }}</span>
            </q-card-section>
            <q-card-section class="q-ml-lg q-mr-lg q-mt-sm q-pa-none q-ma-none" style="max-width: 500px"
                id="disponibilidad-producto" v-show="dialogWithOptions == false" v-if="product.expiration">
                {{ $t("BuyingProcess.products.expiration") }}
                <span class="text-weight-bold">{{ product.expiration }}</span>
            </q-card-section>

            <div class="container-button-like text-center">
                <!-- Texto de falta de puntos -->
                <!-- Se activa en la función showOptions() -->

                <div v-if="points.points.points < product.value">
                    <p class="text-left text-weight-bold lackOfPoints q-mt-sm q-ma-lg">
                        {{ $t("BuyingProcess.products.lack-of-points-1") }}
                        {{ displayRestaOptions() }}
                        {{ $t("BuyingProcess.products.lack-of-points-2") }}
                    </p>
                </div>

                <!-- Botón añadir al carrito -->
                <!-- Activa la función showDialogOptions() -->

                <div>
                    <ButtonOptions v-if="guestMode" to="/signup"
                        id="buttonShowOptions" class="buttonOptions q-mb-md q-mt-xl"
                        style="margin-top: 10vh" v-show="dialogWithOptions == false"
                    >
                        {{ $t("BuyingProcess.products.want-button") }}
                    </ButtonOptions>
                    <ButtonOptions v-else @click="showDialogOptions()"
                        id="buttonShowOptions" class="buttonOptions q-mb-md q-mt-xl"
                        style="margin-top: 10vh" v-show="dialogWithOptions == false"
                        :disabled="points.points.points < product.value || product.stock == 0"
                    >
                        {{ $t("BuyingProcess.products.want-button") }}
                    </ButtonOptions>
                </div>
            </div>

            <!-- Dialog que muestra las opciones a escoger (recoger en tienda o enviar a domicilio) -->
            <!-- :products="product.id" -->
            <div class="text-center q-mt-none q-pt-none">
                <OptionShow v-if="dialogWithOptions" :product="product" :points="points" />
            </div>
        </q-card>
    </q-dialog>
</template>

<script>
import { ref } from "vue";
import { defineAsyncComponent } from "vue";
// import { mapActions } from "vuex";
import ProductPageMixin from "../mixins/ProductPageMixin";
import { getProductImageUrl } from "@/services/helpers/ProductImages.js"


const GoBackButton = defineAsyncComponent(() =>
    import("../../shared/buttons/GoBackButton.vue")
);

const ButtonOptions = defineAsyncComponent(() =>
    import("../../shared/buttons/ButtonOptions.vue")
);

const OptionShow = defineAsyncComponent(() =>
    import("../components/OptionShow.vue")
);

export default {
    mixins: [ProductPageMixin],
    components: {
        GoBackButton,
        ButtonOptions,
        OptionShow,
    },
    computed: {
      settings() {
        return this.$store.state.settings.settings;
      },
    },
    data() {
        return {
            lackOfPoints: false,
            aux_product: "",
            defaultImage: this.defaultImageApp,
        };
    },
    props: {
        dialog: {
            default: false,
        },
        points: {
            type: Object
        },
        product: {
            id: {
                type: Number,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            value: {
                type: Number,
                required: true,
            },
            stock: {
                type: Number,
                required: true,
            },
        },
        guestMode: {
            default: false,
        },
    },
    methods: {
        getProductImageUrl,
        clearProductPage() {
            this.dialogWithOptions = false;
        }
    },
    setup() {
        return {
            dialog: ref(false),
            maximizedToggle: true,
            slide: ref(0),
            dialogWithOptions: ref(false),
        };
    },
    mounted() {
        this.product_aux = this.product;
        // this.getPoints();
    },

    updated() {
        //this.deleteInfo();
        //this.dialogWithOptions = false;
    },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.q-img {
    border-radius: 25px 25px 25px 0px;
    margin: 1rem;
    width: 400px;
    height: 300px;
    object-fit: contain;
}

#button-2 {
    color: white;
    font-size: 30px;
    border-radius: 50%;
}

@media (min-width: 768px) {
    .q-dialog__inner--minimized>div {
        width: 560px;
        position: relative;
        // left: 26%;
        top: 35px;
        min-height: 610px;
        padding-left: 2%;
        padding-right: 2%;
    }
}

@media (max-width: 600px) {
    .q-img {
        width: 90%;
        max-height: 300px;
    }

    .q-card {
        padding-bottom: 150px;
    }
}

.q-card {
    background-color: white;
    max-width: 700px;
}

.title-products {
    font-size: 16px;
    line-height: 1.5rem;
    max-width: 290px;
}

.price-products {
    color: var(--elements-color-primary);
    font-size: 18px;
    width: 120px;
    text-align: right;
}

@media (min-width: 600px) {
    .container-button-like {
        position: relative;
    }
}

.q-card__section--vert {
    padding: 0px;
}

.points {
    border-radius: 25px 25px 25px 0px;
    padding: 0.5rem;

    .title-points {
        font-size: 16px;
        text-transform: lowercase;
    }
}

#disponibilidad-producto {
    font-size: 12px;
}

.lackOfPoints {
    font-size: 12px;
    color: $negative2;
}
</style>
